import React, { useState } from 'react';
import {
  CardHeader,
  Divider,
  Card,
  LinearProgress,
  List,
  ListItem,
  Box,
  Typography,
  styled,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grow,
  IconButton,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SortIcon from '@mui/icons-material/Sort';
import { useDashboard } from 'src/contexts/DashboardContext';
import { Link } from 'react-router-dom';

// Styled Components
const ImageWrapper = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  width: 40,
  height: 40,
}));

const LinearProgressWrapper = styled(LinearProgress)(({ theme }) => ({
  flexGrow: 1,
  marginRight: theme.spacing(1),
  height: 10,
  borderRadius: 5,
  transition: 'all 0.5s ease',
}));

const ListItemWrapper = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 24px',
  borderRadius: 8,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}));

function StudentsByFilters() {
  const { t } = useTranslation();
  const { studentAnalytics, studentFilter, setStudentFilter, loadingAnalytics } = useDashboard();

  // State for controlling sort order ('desc' or 'asc')
  const [sortOrder, setSortOrder] = useState('desc');
  // State to control whether to show all items or just the first six
  const [expanded, setExpanded] = useState(false);

  // Always sort the analytics data (by count in this example)
  const sortedAnalytics = [...studentAnalytics].sort((a, b) =>
    sortOrder === 'desc' ? b.count - a.count : a.count - b.count
  );

  // Determine items to show based on the expanded state
  const itemsToShow = expanded ? sortedAnalytics : sortedAnalytics.slice(0, 6);

  // Function to get the appropriate icon based on filter type
  const getFilterIcon = (filterType) => {
    switch (filterType) {
      case 'location':
        return <LocationCityIcon />;
      case 'class':
        return <ClassIcon />;
      case 'school':
        return <SchoolIcon />;
      default:
        return <LocationCityIcon />;
    }
  };

  return (
    <Card>
      <CardHeader
        title={t('students_by_filters')}
        action={
          <FilterContainer>
            <IconButton
              onClick={() =>
                setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'))
              }
              size="small"
              sx={{ ml: 1 }}
            >
              <SortIcon
                sx={{
                  transform: sortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </IconButton>
            <FormControl variant="outlined" size="small">
              <InputLabel id="filter-label">{t('filter')}</InputLabel>
              <Select
                labelId="filter-label"
                id="filter-select"
                value={studentFilter}
                onChange={(event) => setStudentFilter(event.target.value)}
                label={t('filter')}
              >
                <MenuItem value="location">{t('location')}</MenuItem>
                <MenuItem value="class">{t('class')}</MenuItem>
                <MenuItem value="school">{t('school')}</MenuItem>
              </Select>
            </FormControl>
          </FilterContainer>
        }
      />
      <Divider />
      {loadingAnalytics ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <List disablePadding component="nav">
            {itemsToShow.map((item, index) => (
              <Grow in key={item.id} timeout={(index + 1) * 300}>
                <Box>
                  <ListItemWrapper>
                    <Box display="flex" alignItems="center" width="100%">
                      {/* Filter Icon */}
                      <Box mr={2}>
                        <ImageWrapper>
                          {getFilterIcon(studentFilter)}
                        </ImageWrapper>
                      </Box>

                      {/* Filter-specific Name */}
                      <Box>
                        {studentFilter === 'location' ? (
                          <Link
                            to={`/management/locations/view/${item.id}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <Typography
                              variant="h6"
                              color="text.primary"
                              noWrap
                              sx={{ minWidth: 100, cursor: 'pointer' }}
                            >
                              {item.name}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography
                            variant="h6"
                            color="text.primary"
                            noWrap
                            sx={{ minWidth: 100 }}
                          >
                            {item.name}
                          </Typography>
                        )}
                        <Typography variant="body2" color="text.secondary">
                          {t('count')}: {item.count}
                        </Typography>
                      </Box>

                      {/* Progress Bar and Percentage */}
                      <Box display="flex" alignItems="center" flexGrow={1} ml={2}>
                        <LinearProgressWrapper
                          value={item.percentage}
                          color="primary"
                          variant="determinate"
                        />
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{ minWidth: 40 }}
                        >
                          {item.percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </ListItemWrapper>
                  {index < itemsToShow.length - 1 && <Divider />}
                </Box>
              </Grow>
            ))}
          </List>
          {sortedAnalytics.length > 6 && (
            <Box display="flex" justifyContent="center" p={2}>
              <Button onClick={() => setExpanded(!expanded)}>
                {expanded ? t('show_less') : t('show_more')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default StudentsByFilters;
