import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, MenuItem, Typography, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { deleteFiles } from '../../services/fileUploadService';
import ReusableDialog from 'src/content/pages/Components/Dialogs';
import FileView from './FileView';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/useAuth';
import { getStrongestRoles } from 'src/hooks/roleUtils';

interface UploadedFile {
    id?: number;  // `id` is optional because a file might not be saved yet
    file: File | null;
    fileName: string;
    fileType: string;
    path?: string;
}

interface UploadSectionProps {
    onUploadChange: (files: UploadedFile[]) => void;
    initialDocuments?: UploadedFile[];
    userType?: string;
}

const UploadSection: React.FC<UploadSectionProps> = ({ onUploadChange, initialDocuments = [], userType }) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(initialDocuments);
    const [openDialog, setOpenDialog] = useState(false); // Dialog state
    const [fileToDelete, setFileToDelete] = useState<UploadedFile | null>(null); // File to delete
    const { userRoles } = useAuth();
    const strongestRoles = userRoles ? getStrongestRoles(userRoles) : [];


    useEffect(() => {
        if (initialDocuments.length > 0) {
            setUploadedFiles(initialDocuments);
        }
    }, [initialDocuments]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (!selectedFile || !fileName || !fileType) {
            alert('Please select a file, set a name, and choose a type.');
            return;
        }

        const newFile: UploadedFile = {
            file: selectedFile,
            fileName,
            fileType,
        };

        const updatedFiles = [...uploadedFiles, newFile];
        setUploadedFiles(updatedFiles);
        onUploadChange(updatedFiles);

        // Reset after uploading
        setSelectedFile(null);
        setFileName('');
        setFileType('');
    };

    const handleDelete = (index: number) => {
        const file = uploadedFiles[index];
        if (file.id) {
            // File has an ID, meaning it is already saved in the DB -> show the confirmation dialog
            setFileToDelete(file); // Set the file to delete
            setOpenDialog(true); // Open the confirmation dialog
        } else {
            // File doesn't have an ID -> delete it directly without showing the dialog
            const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
            setUploadedFiles(updatedFiles);
            onUploadChange(updatedFiles);
        }
    };

    const confirmDelete = async () => {
        if (fileToDelete && fileToDelete.id) {
            try {
                // Call the deleteFiles function from the service
                await deleteFiles([fileToDelete.id]);

                // Remove the file from the state after successful deletion
                const updatedFiles = uploadedFiles.filter((file) => file.id !== fileToDelete.id);
                setUploadedFiles(updatedFiles);
                onUploadChange(updatedFiles);
                alert('File deleted successfully');
            } catch (error) {
                console.error('Error deleting file:', error);
                alert('Error deleting file');
            }
        }

        // Close the dialog and reset the state
        setOpenDialog(false);
        setFileToDelete(null);
    };

    // Define the MenuItems arrays for teachers and students
    const teacherMenuItems = [
      <MenuItem key="honorarvertrag" value="honorarvertrag">{t('honorarvertrag')}</MenuItem>,
      <MenuItem key="erweitertes_fuehrungszeugnis" value="erweitertes_fuehrungszeugnis">{t('erweitertes_fuehrungszeugnis')}</MenuItem>,
      <MenuItem key="eidesstattliche_erklaerung" value="eidesstattliche_erklaerung">{t('eidesstattliche_erklaerung')}</MenuItem>,
      <MenuItem key="wettbewerbsverbot_fuer_freie_mitarbeiter" value="wettbewerbsverbot_fuer_freie_mitarbeiter">
        {t('wettbewerbsverbot_fuer_freie_mitarbeiter')}
      </MenuItem>,
      <MenuItem key="immatrikulationsbescheinigung" value="immatrikulationsbescheinigung">
        {t('immatrikulationsbescheinigung')}
      </MenuItem>,
      <MenuItem key="impf_ausweis" value="impf_ausweis">{t('impf_ausweis')}</MenuItem>
    ];

    const studentMenuItems = [
      <MenuItem key="vertrag" value="vertrag">{t('vertrag')}</MenuItem>,
      <MenuItem key="but_antrag" value="but_antrag">{t('but_antrag')}</MenuItem>,
      <MenuItem key="schulbescheide" value="schulbescheide">{t('schulbescheide')}</MenuItem>,
      <MenuItem key="arzt_bescheinigung" value="arzt_bescheinigung">{t('arzt_bescheinigung')}</MenuItem>
    ];

    const customMenuItems = [
        <MenuItem key="other" value="other">{t('other ')}</MenuItem>,
      ];

    return (
        <Box sx={{ p: 3, width: '100%' }}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant="outlined"
                                component="label"
                                fullWidth
                                startIcon={<UploadFileIcon />}
                            >
                                Datei wählen
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={t("file_name")}
                                fullWidth
                                value={fileName}
                                onChange={(e) => setFileName(e.target.value)}
                                placeholder="Dateiname eingeben"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={t("file_type")}
                                select
                                fullWidth
                                value={fileType}
                                onChange={(e) => setFileType(e.target.value)}
                            >
                                <MenuItem disabled value="">
                                    <em>Select file type</em>
                                </MenuItem>
                                { 
                                    userType === 'teacher' 
                                        ? teacherMenuItems 
                                        : userType === 'student' 
                                        ? studentMenuItems 
                                        : customMenuItems 
                                    }
                              </TextField>
                        </Grid>

                        <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                            <Grid item xs={9}>
                                {selectedFile && (
                                    <Typography variant="body2">
                                        File: {selectedFile.name}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpload}
                                    fullWidth
                                >
                                    Datei hochladen
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={5} sx={{ mx: 'auto' }}>
                    <Typography variant="subtitle1" gutterBottom>
                    Hochgeladene Dateien
                    </Typography>
                    {uploadedFiles.length > 0 ? (
                        <Box>
                            <List>
                                {uploadedFiles.map((uploadedFile, index) => (
                                    <Box key={index}>
                                        <Divider />
                                        <ListItem>
                                            <ListItemText
                                                primary={uploadedFile.fileName}
                                                secondary={`Type: ${uploadedFile.fileType}`}
                                            />
                                            {/* Use FileView component */}
                                            {uploadedFile.path && (
                                                <FileView fileId={uploadedFile.id} />
                                            )}
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </Box>
                                ))}
                            </List>
                        </Box>
                    ) : (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80%',
                            minHeight: '150px',
                        }}>
                            <Typography variant="body2" color="textSecondary">
Keine Dateien ausgewählt                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>

            {/* Reusable Dialog for confirming deletion */}
            <ReusableDialog
                open={openDialog}
                title="Confirm Deletion"
                onClose={() => setOpenDialog(false)}
                actions={
                    <>
                        <Button onClick={() => setOpenDialog(false)}>{t("cancel")}</Button>
                        <Button color="error" onClick={confirmDelete}>{t("delete")}</Button>
                    </>
                }
            >
                <Typography>
                    {t("are_you_sure_you_want_to_delete_this_file?")}
                </Typography>
            </ReusableDialog>
        </Box>
    );
};

export default UploadSection;
